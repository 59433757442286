<template>
    <!--  渠道管理 - 渠道管理 - 查看招生情况-->
    <div class="pageContol listWrap templateList">
        <div class="framePage">
            <!-- 头部面包屑 -->
            <div class="framePage-title">
                <span>
                    <em>当前位置：</em>
                    <a href="javascript:;">招生管理</a>
                    <i>></i>
                    <a href="javascript:;" @click="$router.back()">招生管理</a>
                    <i>></i>
                    <a href="javascript:;" class="cur-a">查看招生情况</a>
                </span>
            </div>
            <!-- 主体区域 -->
            <div class="framePage-body">
                <!-- 搜索区域 -->
                <div class="operationControl" style="display: flex; flex-direction: column; align-items: flex-start">
                    <div class="searchbox">
                        <div title="培训名称" class="searchboxItem ">
                            <span class="itemLabel">培训名称:</span>
                            <el-input v-model="searchForm.projectName" placeholder="请输入培训名称" type="text" clearable size="small"/>
                        </div>
                        <div title="报名时间" class="searchboxItem ">
                            <span class="itemLabel">报名时间:</span>
                            <el-date-picker
                                @change="datePickerChange"
                                v-model="registrationTime"
                                type="daterange"
                                size="small"
                                value-format="yyyy-MM-dd"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期">
                            </el-date-picker>
                        </div>
                        <div class="searchboxItem">
                            <el-button type="primary" class="bgc-bv" round @click="getListData(1)">查询</el-button>
                        </div>
                    </div>
                </div>

                <!-- 列表区域 -->
                <div class="framePage-scroll">
                    <div class="ovy-a">
                        <el-table
                            ref="multipleTable"
                            :data="listData"
                            :height="tableHeight"
                            size="small"
                            tooltip-effect="dark"
                            style="width: 100%"
                            :header-cell-style="tableHeader"
                            stripe
                        >
                            <el-table-column
                                label="序号"
                                align="center"
                                type="index"
                                :index="indexMethod"
                                width="50"
                            />
                            <el-table-column
                                label="培训编号"
                                prop="projectCode"
                            />
                            <el-table-column
                                label="培训名称"
                                prop="projectName"
                            />
                            <el-table-column
                                label="招生数量"
                                prop="peopleNum"
                            />
                            <el-table-column
                                label="招生有效期"
                            >
                                <template v-slot="{row}">
                                    {{row.startDate}}~{{row.endDate}}
                                </template>
                            </el-table-column>
                            <el-table-column
                                label="操作"
                                align="center"
                            >
                                <template v-slot="{row}">
                                    <el-button type="text" size="mini" style="padding: 0 5px;" @click="viewStudents(row)">查看学员</el-button>
                                </template>
                            </el-table-column>
                            <Empty slot="empty" />
                        </el-table>
                    </div>
                </div>

                <!-- 分页器 -->
                <PageNum :apiData="pagination" @sizeChange="sizeChange" @getData="currentChange"/>
            </div>
        </div>
    </div>
</template>

<script>
    import { resetKeepAlive } from "@/utils/common";
    import Empty from "@/components/Empty.vue";
    import PageNum from "@/components/PageNum.vue";
    import List from "@/mixins/List";
    export default {
        name:"checkAdmissions",
        components:{
            Empty,
            PageNum
        },
        mixins:[List],
        data(){
            return{
                // 搜索区域表单
                searchForm:{
                    projectName:"",//机构名称
                    startDate:"",//开始时间
                    endDate:"",//结束时间
                },
                registrationTime:[],
                //培训列表数据
                listData:[],
                // 分页器数据
                pagination:{
                    total: 0,//总条数
                    size: 10,//每页几条数据
                    current: 1//当前页
                },
                // 渠道id（路由传参而来）
                channelId:""
            }
        },
        methods:{
          // 序号
          indexMethod(index) {
            return (this.pagination.current - 1) * this.pagination.size + index + 1;
          },
            // 初始化数据
            initData(){
                this.channelId=this.$route.query.channelId;
                this.getListData();
            },
            // 获取列表数据
            getListData(current){
                const {searchForm,pagination,channelId}=this;
                if(current) this.pagination.current=current;
                this.$post("/biz/ct/channel/pageListProject",{
                    pageNum: pagination.current,
                    pageSize: pagination.size,
                    channelId,
                    ...searchForm
                }).then(res=>{
                    this.listData=res?.data?.list||[];
                    pagination.total=res?.data?.total||0;
                }).catch(e=>{
                    console.error("获取列表数据出错",e);
                });
            },
            // 查看学员
            viewStudents(row){
                const {projectId,channelId,projectName,contactUser}=row;
                this.$router.push({
                    path:"/web/institutionalManagement/channelManagement/studentList",
                    query:{
                        projectId,
                        channelId,
                        projectName,
                        contactUser
                    }
                });
            },
            // 报名时间改变
            datePickerChange(e){
                if(!e) return;
                const {searchForm}=this;
                searchForm.startDate=e[0];
                searchForm.endDate=e[1];
            },
            // 当前页改变
            currentChange(current){
                this.pagination.current=current;
                this.getListData();
            },
            // 每页条数改变
            sizeChange(size){
                this.pagination.size=size;
                this.getListData();
            },
            // 设置表格高度
            getTableHeight(opDom = false, page = true) {
                let tHeight = window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 4) * 16;
                if (opDom)  tHeight -= 40 + 0.675 * 16 + 1;
                if (page)  tHeight -= 32;
                this.tableHeight = tHeight;
            },
        },
        // watch:{
        //     $route(to,from){
        //         if(from.path==="/web/institutionalManagement/channelManagement/studentList"){
        //             // to.meta.state=true;
        //             this.getListData();
        //         }
        //         if(to.path!=="/web/institutionalManagement/channelManagement/studentList"){
        //             from.meta.state=false;
        //         }
        //         if(to.path==="/web/institutionalManagement/channelManagement/studentList"){
        //             from.meta.state=true;
        //         }
        //     }
        // },
        created(){
            this.initData();
        },
    }
</script>

<style lang="less" scoped>

</style>